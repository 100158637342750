import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import {
  RESET,
  SCHEMAS_PUT
} from '@catalogit/client-api/lib/constants/action-types.js';
import { ACCOUNT_REFRESH } from '@catalogit/client-api/lib/account/types.js';

import { CURRENT_VERSION } from '../../constants/action-types.js';

import type { VersionState, CurrentVersionActionTypes } from './types.js';

const initialState: VersionState = {
  sha: '-unknown-',
  schema_stale: false,
  account_stale: false
};

export default function currentVersionReducer(
  state: VersionState = initialState,
  action: CurrentVersionActionTypes
) {
  switch (action.type) {
    case RESET: {
      return { ...state, account_stale: false, schema_stale: false };
    }

    case CURRENT_VERSION:
      if (action.meta.state === FULFILLED) {
        if (state.sha !== action.payload.sha) {
          return { ...state, sha: action.payload.version_sha };
        }
      }
      return state;

    case ACCOUNT_REFRESH: {
      return { ...state, account_stale: true };
    }

    case SCHEMAS_PUT:
      return { ...state, schema_stale: true };

    default:
      return state;
  }
}
