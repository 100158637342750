import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import { RESET } from '@catalogit/client-api/lib/constants/action-types.js';

import { SET_UI_STATE } from '../../constants/action-types.js';

import type { UIState, UIStateActionTypes } from './types.js';

const initialState: UIState = {
  expanded_collection_sets: []
};

export default function uiStateReducer(
  state: UIState = initialState,
  action: UIStateActionTypes
) {
  switch (action.type) {
    case RESET: {
      return { ...state, expanded_collection_sets: [] };
    }

    case SET_UI_STATE:
      if (action.meta.state === FULFILLED) {
        return { ...state, expanded_collection_sets: action.payload };
      }
      return state;

    default:
      return state;
  }
}
