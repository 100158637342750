import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import type {
  CITState,
  CITThunkDispatch
} from '@catalogit/client-api/lib/types/store.js';
import {
  dispatchError,
  makeMeta
} from '@catalogit/client-api/lib/utils/fetch.js';

import { citFetch } from '@catalogit/client-api/lib/actions/cit-fetch.js';

import { CURRENT_VERSION } from '../../constants/action-types.js';

export function getVersionManifest(/* rollbar?: Rollbar */) {
  return async (dispatch: CITThunkDispatch, getState: () => CITState) => {
    const baseAction = {
      type: CURRENT_VERSION
    };

    let response;
    try {
      response = await citFetch(dispatch, getState, `/version.json`);
    } catch (err: any) {
      // TODO: rollbar shouldn't be referenced here from window ???:
      // window.rollbar.error(
      //   err,
      //   `Request for CURRENT_VERSION (/version.json) failed`
      // );
      return dispatchError(dispatch, baseAction, err);
    }

    if (!response) {
      console.error(`undefined returned from request to /version.json`);
      // TODO: rollbar shouldn't be referenced here from window ???:
      // window.rollbar.error(
      //   `undefined returned from request to /version.json`
      // );
      return;
    }

    const action = {
      ...baseAction,
      meta: makeMeta(FULFILLED),
      payload: response
    };

    dispatch(action);

    return action;
  };
}
