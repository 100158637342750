import { createTheme /*, responsiveFontSizes */ } from '@material-ui/core/styles';

import '@catalogit/common-components-ts/lib/cit-mui-theme.js';

const rawTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#0086A8',
      main: '#005F91',
      dark: '#11374B'
    },
    secondary: {
      main: '#FF8F00',
      contrastText: '#fff'
    },
    common: {
      black: '#000',
      white: '#fff',
      transparent: 'rgba(0, 0, 0, 0)',
      fullBlack: 'rgba(0, 0, 0, 1)',
      darkBlack: 'rgba(0, 0, 0, 0.87)',
      lightBlack: 'rgba(0, 0, 0, 0.54)',
      minBlack: 'rgba(0, 0, 0, 0.26)',
      faintBlack: 'rgba(0, 0, 0, 0.12)',
      fullWhite: 'rgba(255, 255, 255, 1)',
      darkWhite: 'rgba(255, 255, 255, 0.87)',
      lightWhite: 'rgba(255, 255, 255, 0.54)'
    },
    background: {
      default: '#FFF' // '#FFFAF5'
    },
    warning: { main: '#F44336' },
    success: { main: '#1FEB89' }
  },

  overrides: {
    MuiInput: {
      // ??? not sure if this adds visual noise or not (i.e. the underline color is different from text color)
      underline: {
        '&&&&:hover:before': {
          borderBottom: '2px solid #005F91'
        },
        '&:after': {
          borderBottom: '2px solid #005F91'
        }
      }
    }
  }
});

// const theme = responsiveFontSizes(rawTheme);

export default rawTheme;
