import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { isNative } from '@catalogit/common/lib/constants/runtime.js';

import * as apiReducers from '@catalogit/client-api/lib/reducers/index.js';

import { reconcileCredentials } from '@catalogit/client-api/lib/actions/auth.js';
import { AuthStateRecord, AuthRecord } from '@catalogit/client-api/lib/auth/types.js';
import { setOrDefaultInitialState } from '@catalogit/client-api/lib/reducers/object-import-memory.js';

import version from '../version.json';

import * as appReducers from './reducers.js';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const DEV = import.meta.env.NODE_ENV === 'development';

// console.log('DEV', DEV);
// console.log('isNative', isNative);

const initialState = {
  auth: AuthStateRecord({
    local: AuthRecord({
      access_token: localStorage.getItem('accessToken') || undefined,
      refresh_token: localStorage.getItem('refreshToken') || undefined,
      email: localStorage.getItem('email') || undefined,
      expires_in: localStorage.getItem('expiresIn') || undefined
    })
  }),

  config: {
    IDENTITY_URL: import.meta.env.VITE_IDENTITY_URL
      ? import.meta.env.VITE_IDENTITY_URL
      : 'https://localhost:4100',
    API_URL: import.meta.env.VITE_API_URL ? import.meta.env.VITE_API_URL : 'https://localhost:4000',
    WEBAPP_URL: import.meta.env.VITE_WEBAPP_URL
      ? import.meta.env.VITE_WEBAPP_URL
      : 'https://localhost:3000',
    HUB_URL: import.meta.env.VITE_HUB_URL ? import.meta.env.VITE_HUB_URL : 'https://localhost:3100',

    VERSION_SHA: version.version_sha
  },

  currentVersion: {
    sha: '',
    schema_stale: false,
    account_stale: false
  },

  uiState: {
    expanded_collection_sets: []
  },

  objectImportMemory: setOrDefaultInitialState(localStorage.getItem('mapping_memory'))
};

// TODO: ???: fix deprecation and cast to any
const store = createStore(
  combineReducers({
    ...apiReducers,
    ...appReducers
  }),
  initialState,
  DEV
    ? composeEnhancers(applyMiddleware(thunk as any, logger as any))
    : isNative
      ? applyMiddleware(thunk as any)
      : applyMiddleware(thunk as any, logger as any)
);

store.dispatch(reconcileCredentials(store.getState().auth));

export default store;
