import type { ConfigState } from './types.js';

const initialState: ConfigState = {
  IDENTITY_URL: '',
  API_URL: '',
  WEBAPP_URL: '',
  HUB_URL: '',
  VERSION_SHA: ''
};

// Note... this state is immutable and can only be set during app initialization
export default function configReducer(state: ConfigState = initialState) {
  return state;
}
