import '@capacitor/core';

import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import Rollbar from 'rollbar';

import {
  isNative,
  needsStatusBar,
  hiDPI,
  hasTouch
} from '@catalogit/common/lib/constants/runtime.js';

import apiListenerBridge from '@catalogit/client-api/lib/actions/api-listener-bridge.js';

import reduxStore from './store/index.js';

import initServices, { apiListener } from './services/index.js';

import version from './version.json';

import App from './app.js';
// import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';

import muiTheme from './mui-theme';

// declare global {
//   interface Window {
//     cit: {
//       rollbarConfig: Rollbar.Configuration;
//     };
//     gtag: any;
//   }
// }

console.log(`Touch capable device: ${hasTouch ? 'yes' : 'no'}`);
console.log(`HiDPI device: ${hiDPI ? 'yes' : 'no'}`);

// setup rollbar
window.rollbar = new Rollbar({
  ...window.cit.rollbarConfig,
  payload: {
    ...window.cit.rollbarConfig.payload,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: version.version_sha,
        guess_uncaught_frames: true
      }
    }
  }
});

// send info ping to rollbar
// window.rollbar.info(`app launching; version: ${version.version_sha}`, {custom: {
//   version: version.version_sha,
//   NODE_ENV: import.meta.env.NODE_ENV,
//   VITE_GA_TRACKING_ID: import.meta.env.VITE_GA_TRACKING_ID
// }});

// setup for getting api updates via websockets.  Make sure services are initialized
// prior to initializing apiListenerBridge
initServices(reduxStore.getState().config.API_URL);
apiListenerBridge(apiListener, reduxStore);

const startApp = async () => {
  // try {
  //   if (import.meta.env.VITE_IDENTITY_URL) {
  //     const res = await fetch(import.meta.env.VITE_IDENTITY_URL);
  //     if (res.status !== 200) {
  //       throw new Error(`Bad status code from ${import.meta.env.VITE_IDENTITY_URL}: ${res.status}                                                    `);
  //     }
  //     console.log(`import.meta.env.VITE_IDENTITY_URL status OK (${import.meta.env.VITE_IDENTITY_URL})`);
  //   } else {
  //     console.log('missing required import.meta.env.VITE_IDENTITY_URL');
  //   }

  //   if (import.meta.env.VITE_API_URL) {
  //     const res = await fetch(`${import.meta.env.VITE_API_URL}/api/status`);
  //     if (res.status !== 200) {
  //       throw new Error(`Bad status code from ${import.meta.env.VITE_API_URL}/api/status: ${res.status}`);
  //     }
  //     console.log(`import.meta.env.VITE_API_URL status OK (${import.meta.env.VITE_API_URL})`);
  //   } else {
  //     console.log('missing required import.meta.env.VITE_API_URL');
  //   }
  // } catch (err) {
  //   console.log(`Unexpected error checking connectivity: ${err.message}`);
  //   throw err;
  // }

  const rootElement = document.getElementById('root');
  if (!rootElement) {
    throw new Error('missing root element - unable to continue');
  }

  if (needsStatusBar()) {
    window.document.documentElement.style.height = '100vh';
    window.document.body.style.height = '100vh';
    rootElement.style.height = '100vh';
  } else {
    window.document.documentElement.style.height = '100%';
    window.document.body.style.height = '100%';
    rootElement.style.height = '100%';
  }

  const theme = responsiveFontSizes(createTheme(muiTheme));

  ReactDOM.render(
    React.cloneElement(isNative ? <MemoryRouter /> : <BrowserRouter />, {
      children: (
        <Provider store={reduxStore}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Provider>
      )
    }),
    rootElement
  );
};

startApp();
