import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { type IConnectivityRecord } from '@catalogit/common/lib/types/connectivity.js';

// Must be disconnected for DELAY millis for the connectivity indicator to appear.
// This prevents flapping
const DELAY = 2000;

interface ConnectivityIndicatorProps {
  connectivityState: IConnectivityRecord;
}

export default function ConnectivityIndicator({
  connectivityState
}: ConnectivityIndicatorProps): React.ReactElement | null {
  const theme = useTheme();

  const [connectivity, setConnectivity] = useState(connectivityState);
  const [visible, setVisible] = useState(false);
  const [delayId, setDelayId] = useState(0);

  useEffect(() => {
    if (connectivityState.get('connected') === false) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    // prev-connected => curr-not-connected
    if (
      connectivity.get('connected') === true &&
      connectivityState.get('connected') === false
    ) {
      // console.log('prev-connected => curr-not-connected')
      // create delay timer
      setDelayId(
        window.setTimeout(() => {
          setVisible(true);
          setDelayId(0);
        }, DELAY)
      );
    }
    // prev-not-connected => curr-connected
    else if (
      connectivity.get('connected') === false &&
      connectivityState.get('connected') === true
    ) {
      // console.log('prev-not-connected => curr-connected')
      if (delayId) {
        window.clearTimeout(delayId);
        setDelayId(0);
      }
      if (visible) {
        setVisible(false);
      }
    }

    setConnectivity(connectivityState);
  }, [connectivityState]);

  if (visible) {
    return (
      <div
        style={{
          position: 'fixed',
          left: '12%',
          right: '12%',
          top: theme.spacing(2),
          zIndex: 2000,
          backgroundColor: 'rgba(255, 255, 204, 0.80)',
          color: 'rgba(0, 0, 0, 0.54)',
          textAlign: 'center',
          borderRadius: 5,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1)
        }}
      >
        Reconnecting to CatalogIt
      </div>
    );
  } else {
    return null;
  }
}
