/**
 * Support for universal links (both iOS and Android)
 */

import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { App, type URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // console.log('AppUrlListener appUrlOpen event', event);

      // Example url: https://catalogit.app/entry/:uuid
      // slug = /entry/:uuid

      const slug = event.url.split('.app').pop();

      // console.log(`AppUrlListener URL slug: ${slug}`);

      if (slug) {
        history.push(slug);
      }

      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;
