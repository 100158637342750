import React from 'react';

import { isNative } from '@catalogit/common/lib/constants/runtime.js';

export type HOCPropsType = {
  location: { [key: string]: any };
  match: { isExact: boolean; path: string };
};

const UUID_REGEX =
  /\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/g;

function pathScrubber(path: string) {
  return path.replace(UUID_REGEX, '');
}

function trackPage(page: string, options: { [key: string]: any } = {}) {
  if (isNative) {
    console.log(`window.ga.trackView('${page})'`);
    // window.ga.trackView(page);
  } else {
    const { title } = document;
    const verticalBarIndex = title.lastIndexOf('|');
    window.gtag('event', 'page_view', {
      page_location: pathScrubber(page),
      page_title: verticalBarIndex > -1 ? title.substring(verticalBarIndex + 1) : title,
      ...options
    });
  }
}

const withGATracker = (WrappedComponent: any, options: { [key: string]: any } = {}) => {
  if (!WrappedComponent) {
    return null;
  }

  class HOC extends React.Component<HOCPropsType, {}> {
    componentDidMount() {
      // console.log('componentDidMount', this.props);
      const page = this.props.match.isExact ? this.props.match.path : this.props.location.pathname;
      trackPage(page, options);
    }

    // componentWillUnmount() {
    //   console.log('componentWillUnmount', this.props);
    // }

    componentDidUpdate(prevProps: HOCPropsType) {
      const currentPage = this.props.match.isExact
        ? this.props.match.path
        : this.props.location.pathname;
      const prevPage = prevProps.match.isExact ? prevProps.match.path : prevProps.location.pathname;
      // console.log('componentDidUpdate', nextProps, currentPage, nextPage);

      if (currentPage !== prevPage) {
        trackPage(currentPage, options);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return HOC;
};

export default withGATracker;
