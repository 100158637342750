import { App as CapApp, type AppInfo } from '@capacitor/app';

import React, { useState, useCallback, useEffect } from 'react';

import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';

import { Button, Snackbar, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { isNative, isNativeAndroid } from '@catalogit/common/lib/constants/runtime';
import { retry } from '@catalogit/common/lib/utils/retry';

import { FULFILLED, REJECTED } from '@catalogit/client-api/lib/constants/states';
import { getMe, resetState } from '@catalogit/client-api/lib/actions/user';
import { getSchemas } from '@catalogit/client-api/lib/actions/owl-class-schema';
import { getEntryClasses } from '@catalogit/client-api/lib/actions/owl-subclasses';
import { getCurrentAccount } from '@catalogit/client-api/lib/utils/account';
// import { type AppThunkDispatch } from './store/types';

import { CITContext, getCITContext } from '@catalogit/common-components-ts/lib/cit-context';

import PrimarySkeleton from '@catalogit/common-components-ts/lib/layout/PrimarySkeleton';

import ConnectivityIndicator from './components/connectivity-indicator';

import ErrorBoundary from './ErrorBoundary';

import AppUrlListener from './AppUrlListener';

import withGATracker from './withGATracker';

import { getVersionManifest } from './store/current-version/actions';

import { apiListener } from './services';

import type { CITContextProps } from '@catalogit/common-components-ts/lib/cit-context.js';

import { type AppState } from './store/types.js';
import type { VersionState } from './store/current-version/types';

// ???: TODO: fix any cast
const makeBundle = (bundleLoader: any) =>
  withGATracker(React.lazy(() => retry(bundleLoader))) as any;

const Authentication = makeBundle(() => import('./containers/auth/Authentication'));
const Logout = makeBundle(() => import('./containers/auth/Logout'));

const ProfileClasses = makeBundle(() => import('./containers/ProfileClasses'));
const ProfileInstances = makeBundle(() => import('./containers/ProfileInstances'));
const ProfileInstanceView = makeBundle(() => import('./containers/ProfileView'));
const ProfileInstanceCreate = makeBundle(() => import('./containers/ProfileCreate'));
const ProfileInstanceEdit = makeBundle(() => import('./containers/ProfileEdit'));
const ProfileImport = makeBundle(() => import('./containers/ProfileImport'));
const ProfileExport = makeBundle(() => import('./containers/ProfileExport'));
const ProfilePrint = makeBundle(() => import('./containers/ProfilePrint'));
const ProfileActions = makeBundle(() => import('./containers/ProfileActions'));

const Collection = makeBundle(() => import('./containers/Collection'));

const CollectionPrint = makeBundle(() => import('./containers/CollectionPrint'));
const CollectionSettings = makeBundle(() => import('./containers/CollectionSettings'));

const EntryView = makeBundle(() => import('./containers/EntryView'));
const EntryCreate = makeBundle(() => import('./containers/EntryCreate'));
const EntryEdit = makeBundle(() => import('./containers/EntryEdit'));
const EntryImport = makeBundle(() => import('./containers/EntryImport'));
const EntryBulkEdit = makeBundle(() => import('./containers/EntryBulkEdit'));
const EntryExport = makeBundle(() => import('./containers/EntryExport'));
const EntryPrint = makeBundle(() => import('./containers/EntryPrint'));
const EntryActions = makeBundle(() => import('./containers/EntryActions'));

const Settings = makeBundle(() => import('./containers/Settings'));
const Help = makeBundle(() => import('./containers/Help'));

const EntryEmail = makeBundle(() => import('./containers/EntryEmail'));

const WebPubishing = makeBundle(() => import('./containers/WebPublishing'));

class _NoMatch extends React.Component<{}> {
  render() {
    return (
      <div style={{ margin: 40 }}>
        404 No Match
        <br />
        <Button onClick={() => (window.location.href = '/')}>Continue</Button>
      </div>
    );
  }
}

const NoMatch = withGATracker(_NoMatch) as any;

function AuthedRoutes(): React.ReactElement {
  const [citContext, setCitContent] = useState<CITContextProps | void>();
  const [showResyncDataPopup, setShowResyncDataPopup] = useState(false);
  const [appInfo, setAppInfo] = useState<AppInfo | void>();
  const [error, setError] = useState<string | void>();

  const dispatch = useDispatch();

  const [
    auth,
    connectivityState,
    versionSHA,
    staleSchema,
    staleAccount,
    owlClassSchemaState,
    userState,
    accountState
  ] = useSelector((state: AppState) => {
    if (!state.currentVersion) {
      window.rollbar.error('state missing currentVersion property');
    }

    const { currentVersion } = state || ({} as VersionState);
    return [
      state.auth,
      state.connectivity,
      currentVersion && state.currentVersion.sha,
      currentVersion.schema_stale,
      currentVersion.account_stale,
      state.owlClassSchema,
      state.user,
      state.account
    ] as const;
  });

  const [currentVersionSHA, setCurrentVersionSHA] = useState(versionSHA);

  // init
  useEffect(() => {
    if (isNative) {
      CapApp.getInfo().then((appInfo) => {
        setAppInfo(appInfo);
      });
    }
  }, []);

  useEffect(() => {
    // if the userState doesn't have a _meta property then assume it
    // needs to be loaded
    if (userState._meta === undefined) {
      // load user
      // console.log('No userState._meta; assume virgin state and demand load');
      dispatch(getMe());
    } else {
      switch (userState.getIn(['_meta', 'state'])) {
        case FULFILLED:
          // console.log('appInfo, dispatch, userState changed and userState fulfilled');

          // if owlClassSchemaState is empty then we need to load schemas and classes.  Once
          // it's loading it will minimally have meta.state (FULFILLED, PENDING, or REJECTED)
          if (owlClassSchemaState.size === 0) {
            // console.log('missing owlClassSchemaState; load schemas and entry classifications', {
            //   owlClassSchemaState: owlClassSchemaState.toJS()
            // });

            // proactively load schemas and classes so they're available when needed
            dispatch(getSchemas());
            dispatch(getEntryClasses());

            // tell rollbar the current user_id and account_id
            window.rollbar.configure({
              payload: {
                session: {
                  user_id: userState.user_id,
                  account_id: userState.current_account_id,
                  version: appInfo && appInfo.version,
                  build: appInfo && appInfo.build
                }
              }
            });

            // update GA
            if (isNative) {
              // console.log(`window.ga.setUserId('${userState.user_id}')`);
              // window.ga.setUserId(userState.user_id);
            } else {
              window.gtag('set', { userId: userState.user_id });
            }
          }
          break;

        case REJECTED: {
          const err = userState.get('_error');
          const msg = (err.detail && err.detail.message) || err.message;

          if (msg === 'Invalid Refresh Token' || msg === 'Refresh Token has expired') {
            // force a refresh -- stored refresh token is invalid so "restart" the session
            window.location.href = '/';
            return;
          }

          const errorMsg = `Unexpected error loading CatalogIt: ${msg}`;
          console.log(errorMsg);
          setError(errorMsg);
          break;
        }

        default:
          break;
      }
    }
  }, [appInfo, dispatch, owlClassSchemaState, userState]);

  const { access_token: accessToken } = auth.active;
  const { user_id: userId, current_account_id: currentAccountId } = userState;

  // if we have an apiListener, accessToken, userId, and currentAccountId then authenticate
  // the apiListener socket channel
  useEffect(() => {
    // console.log({ accessToken, userId, currentAccountId });
    if (apiListener && accessToken && userId && currentAccountId) {
      apiListener.authenticate(accessToken, userId, currentAccountId, true);

      // check for app updates (unless native app) anytime our access_token changes
      if (!isNative) {
        // check for a version update anytime the access_token gets refreshed... that's
        // probably a good enough trigger for when to regularly check
        dispatch(getVersionManifest());
      }
    }
  }, [accessToken, userId, currentAccountId, dispatch]);

  // NOTE: we explicity being reactive on currAccount and currPreferences
  // from state -- NOT context
  const currAccount = getCurrentAccount(accountState, userState);
  const currPreferences = accountState.getIn([userState.current_account_id, 'preferences']);

  // useEffect(() => {
  //   console.log({ currAccount });
  // }, [currAccount]);

  // useEffect(() => {
  //   console.log({ currPreferences });
  // }, [currPreferences]);

  // update context when necessary...
  useEffect(() => {
    // possible that currAccount can be undefined if it was just deleted
    if (currAccount) {
      // console.log('update context', { currentAccountId, currAccount, currPreferences, userState });
      setCitContent(getCITContext(userState, currAccount));
    }
  }, [currentAccountId, currAccount, currPreferences, userState]);

  // show/hide resync popup in
  const inSync = connectivityState.in_sync == null ? true : connectivityState.in_sync;
  useEffect(() => {
    // NOTE: the logic here is a bit convoluted because we want to show the resync popup
    // only if we're not inSync which is the opposite of showResyncDataPopup.  It's only
    // if they equal that we want to change the state of showResyncDataPopup
    // console.log('setShowResyncDataPopup', { inSync });
    setShowResyncDataPopup(!inSync);
  }, [inSync]);

  // stale account
  useEffect(() => {
    // console.log('setShowResyncDataPopup', { staleAccount });
    setShowResyncDataPopup(staleAccount);
  }, [staleAccount]);

  // stale schema
  useEffect(() => {
    // console.log('setShowResyncDataPopup', { staleSchema });
    setShowResyncDataPopup(staleSchema);
  }, [staleSchema]);

  // if the currentVersionSHA is empty then we're in the bootstrap state
  // (or something is very wrong) and we just want to get everything in sync
  useEffect(() => {
    if (!currentVersionSHA) {
      setCurrentVersionSHA(versionSHA);
    }
  }, [versionSHA, currentVersionSHA]);

  // new version handlers
  const handleCloseNewVersion = (_e: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setCurrentVersionSHA(versionSHA);
  };

  const handleReload = () => {
    window.location.reload();
  };

  // sync handlers
  const handleResyncDataClose = (_e: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowResyncDataPopup(false);
  };

  const handleResync = () => {
    dispatch(resetState());
    setShowResyncDataPopup(false);
  };

  const theme = useTheme();

  if (!citContext) {
    // console.log('no citContext');
    return (
      <PrimarySkeleton>
        {error ? (
          <div style={{ margin: 40 }}>
            <Typography style={{ marginBottom: 16 }}>{error}</Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => (window.location.href = '/')}
            >
              Try Again
            </Button>
          </div>
        ) : null}
      </PrimarySkeleton>
    );
  }

  /* ???: would access-token be better than props.userState.email ???: */
  if (!userState.email) {
    // console.log('no userState.email');
    return <PrimarySkeleton />;
  }
  return (
    <CITContext.Provider value={citContext}>
      {error ? (
        <div style={{ margin: 40 }}>
          <Typography style={{ marginBottom: 16 }}>{error}</Typography>
          <Button variant='contained' color='primary' onClick={() => (window.location.href = '/')}>
            Try Again
          </Button>
        </div>
      ) : (
        <Switch>
          <Route exact path='/' component={Collection} />
          <Route exact path='/index.html' component={Collection} />
          <Route exact path='/collections' component={Collection} />

          {/*
          <Route exact path='/scan' component={} />
          */}

          <Route exact path='/collections/create' component={CollectionSettings} />

          <Route exact path='/collections/:cuid/print' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/print/label' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/print/table' component={CollectionPrint} />

          <Route exact path='/collections/:cuid/report/insurance' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/inventory' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/valuation' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/appraisal' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/condition' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/location' component={CollectionPrint} />
          <Route exact path='/collections/:cuid/report/artistcatalog' component={CollectionPrint} />

          <Route exact path='/collections/:id/settings' component={CollectionSettings} />

          <Route exact path='/collections/:collectionId/import/:uuid' component={EntryImport} />
          <Route exact path='/collections/:collectionId/export' component={EntryExport} />

          <Route
            exact
            path='/collections/:collectionId/entries/:entryId/print'
            component={EntryPrint}
          />
          <Route
            exact
            path='/collections/:collectionId/entries/:entryId/print/table'
            component={EntryPrint}
          />
          <Route
            exact
            path='/collections/:collectionId/entries/:entryId/print/label'
            component={EntryPrint}
          />

          <Route
            exact
            path='/collections/:collectionId/entries/:entryId/email'
            component={EntryEmail}
          />

          <Route exact path='/collections/:collectionId/entries/:entryId' component={EntryView} />

          <Route exact path='/collections/:cuid' component={Collection} />

          <Route exact path='/entry/create' component={EntryCreate} />
          <Route exact path='/entry/edit/:entryId' component={EntryEdit} />
          <Route exact path='/entry/bulk-edit' component={EntryBulkEdit} />

          <Route exact path='/entry/:entryId/print' component={EntryPrint} />
          <Route exact path='/entry/:entryId/print/table' component={EntryPrint} />
          <Route exact path='/entry/:entryId/print/label' component={EntryPrint} />

          <Route exact path='/entry/:entryId/edit' component={EntryEdit} />

          <Route exact path='/entry/:entryId/email' component={EntryEmail} />

          <Route exact path='/entry/:entryId/actions' component={EntryActions} />

          <Route path='/entry/:entryId' component={EntryView} />

          <Route exact path='/profiles' component={ProfileClasses} />

          <Route path='/profiles/instance/edit/:instanceURI' component={ProfileInstanceEdit} />
          <Route path='/profiles/instance/:instanceURI/edit' component={ProfileInstanceEdit} />
          <Route path='/profiles/instance/:instanceURI/print' component={ProfilePrint} />
          <Route path='/profiles/instance/:instanceURI/actions' component={ProfileActions} />
          <Route path='/profiles/instance/:instanceURI/report/usage' component={ProfilePrint} />
          <Route path='/profiles/instance/:instanceURI' component={ProfileInstanceView} />

          <Route path='/profiles/class/create/:classURI' component={ProfileInstanceCreate} />
          <Route path='/profiles/class/:classURI/import/:uuid' component={ProfileImport} />
          <Route path='/profiles/class/:classURI/export' component={ProfileExport} />
          <Route path='/profiles/class/:classURI' component={ProfileInstances} />

          <Route path='/help' component={Help} />
          <Route path='/publishing' component={WebPubishing} />
          <Route path='/settings' component={Settings} />

          <Route component={NoMatch} />
        </Switch>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={versionSHA !== '' && versionSHA !== currentVersionSHA}
        onClose={handleCloseNewVersion}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>A new version is available</span>}
        action={[
          <Button key='update' color='secondary' size='small' onClick={handleReload}>
            Update
          </Button>,
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={handleCloseNewVersion}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
      <Snackbar
        style={{ zIndex: theme.zIndex.modal - 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={showResyncDataPopup}
        onClose={handleResyncDataClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>You're out of sync</span>}
        action={
          <Button key='refresh' color='secondary' onClick={handleResync}>
            Refresh
          </Button>
          // <IconButton
          //   key='close'
          //   aria-label='Close'
          //   color='inherit'
          //   onClick={this.handleResyncDataClose}
          // >
          //   <CloseIcon />
          // </IconButton>
        }
      />
      <ConnectivityIndicator connectivityState={connectivityState} />
    </CITContext.Provider>
  );
}

interface PrivateRouteProps {
  component: React.Component<unknown>;
}

function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps): React.ReactElement {
  const refreshToken = useSelector((state: AppState) => state.auth.active.refresh_token);

  return (
    <Route
      {...rest}
      render={(props) =>
        refreshToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function App(): React.ReactElement {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleBackKeyDown = useCallback(() => {
    if (window.location.href !== '/') {
      history.goBack();
      return;
    }
    return;
  }, [history]);

  useEffect(() => {
    if (isNativeAndroid()) {
      window.document.addEventListener('backbutton', handleBackKeyDown, false);

      return function () {
        window.document.removeEventListener('backbutton', handleBackKeyDown, false);
      };
    }
  }, [handleBackKeyDown]);

  useEffect(() => {
    // changes to the current location get posted to GA.
    if (window.dataLayer) {
      // console.log(`GA analytics tracking: ${pathname}`);
      window.dataLayer.push({
        event: 'pageview',
        pageviewPath: pathname
      });
    }
  }, [pathname]);

  return (
    // <React.StrictMode>
    <ErrorBoundary>
      <React.Suspense fallback={<PrimarySkeleton />}>
        <AppUrlListener />
        <Switch>
          <Redirect exact from='/signup' to='/signup/plan' />
          <Redirect exact from='/login' to='/login/credentials' />
          <Redirect exact from='/reset' to='/reset/email' />
          <Redirect exact from='/auth' to='/auth/start' />
          <Route
            exact
            path={['/signup/:id', '/login/:id', '/reset/:id', '/auth/:id']}
            component={Authentication}
          />
          <Route path='/logout' component={Logout} />

          <PrivateRoute path='/' component={AuthedRoutes} />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
    // </React.StrictMode>
  );
}

export default App;
