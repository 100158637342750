import React from 'react';

import '@catalogit/common-components-ts/lib/rollbar';

import ErrorView from './ErrorView.js';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface StateType {
  hasError: boolean;
  error: Error | undefined;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, StateType> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined
    };
  }

  static getDerivedStateFromError(error: Error): {
    hasError: boolean;
    error: Error;
  } {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: unknown): void {
    window.rollbar.error('Unexpected app error', error, { info });
    console.log('Unexpected error', error, { info });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      console.log(this.state.error);
      return <ErrorView message={this.state.error?.message} />;
    }

    return this.props.children;
  }
}
